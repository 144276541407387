<div class="modulo banner-slider" (window:resize)="onResize()">
      <div #banner class="banner-slider-slick-content">
        <div *ngFor="let i of data.banners;let y = index">
          <div class="item slider-item" *ngIf="!i.link_ativo">
            <img *ngIf="width <= 700" width="100%" height="500px" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
            <img *ngIf="width > 700" width="100%" height="500px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
          <div *ngIf="i.link_ativo" [ngSwitch]="i.link_tipo">
            <a routerLink="/produtos/colecao/{{i.colecao}}" (click)="toRoute(i)" *ngSwitchCase="'4'">
              <div class="item slider-item">
                <img *ngIf="width <= 700" width="100%" height="500px" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
                <img *ngIf="width > 700" width="100%" height="500px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
              </div>  
            </a>
            <a routerLink="/produtos/{{i.categoria}}" (click)="toRoute(i)" *ngSwitchCase="'3'">
              <div class="item slider-item">
                <img *ngIf="width <= 700" width="100%" height="500px" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
                <img *ngIf="width > 700" width="100%" height="500px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
              </div>  
            </a>
            <a routerLink="/produtos/{{i.categoria}}/{{i.produto_apelido}}" (click)="toRoute(i)" *ngSwitchCase="'2'">
              <div class="item slider-item">
                <img *ngIf="width <= 700" width="100%" height="500px" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
                <img *ngIf="width > 700" width="100%" height="500px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
              </div>   
            </a> 
            <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
              <div class="item slider-item">
                <img *ngIf="width <= 700" width="100%" height="500px" [src]="i.imagem_mobile" [alt]="i.titulo"  class="img-fluid" />
                <img *ngIf="width > 700" width="100%" height="500px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
              </div>  
            </a>   
          </div>
        </div>
      </div>
      <div class="navigate">
        <div class="navigate-item prev" (click)="prev()">
            <div class="content">
                <img src="images/icons/prev.svg" alt="Prev" width="40px" />
            </div>
        </div> 
        <div class="navigate-item next" (click)="next()">
            <div class="content">
                <img src="images/icons/next.svg" alt="Next" width="40px" />
            </div>
        </div>    
    </div>  
</div>

    
    
      