<div class="module-parceiros">
      <div class="module-title" *ngIf="data.mostrar_titulo">
          <div class="container">
              <h2 class="wow slideInDown" data-wow-dalay="1s">{{data.titulo}}</h2>
              <h3 *ngIf="data.mostrar_subtitulo" class="wow slideInUp" data-wow-dalay="1.5s">{{data.subtitulo}}</h3>
          </div>
      </div>
      <div class="module-content">
            <div class="container">
              <div class="row"> 
                  <div class="col-md-4 wow slideInUp" data-wow-delay="0.5s" *ngFor="let d of data.parceiros;let i=index;">
                      <parceiro-item [data]="d"></parceiro-item>
                  </div>
              </div>
            </div>
      </div>
</div>
  