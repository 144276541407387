import { Component, Input, OnInit } from '@angular/core';

declare var $:any;
declare var anime:any;

// @ts-ignore
@Component({
  selector: 'modulo-pilares',
  templateUrl: './pilares.component.html',
  styleUrls: ['./pilares.component.scss']
})
export class PilaresComponent implements OnInit{
  
  @Input("data") data:any = {}; 
  @Input("classCustom") classCustom = ""; 
  @Input("template") template = null;  
  
  /**
   * 
   * Set animation
   * 
   */
  setAnimation(){ 

    var element:any = document.getElementsByClassName("text-animation")[0];

    // replace each char with <span class="letter">{char} </span>
    element.innerHTML = element.textContent.replace(/\S/g,'<span class="letter">$&</span>');

    anime.timeline({loop:true})
    .add({
      targets:'.text-animation .letter',
      scale:[3,1],
      opacity:[0,1],
      translateZ:0,
      duration:2000,
      easing:"easeOutExpo",
      delay:(elem:any,index:any) => index*40
    })
    .add({
      targets:'.text-animation',
      opacity:0,
      duration:2000,
      delay:1000,
      easing:"easeOutExpo"
    })

  }
  /**
   * 
   * Init
   * 
   ***/
  ngOnInit():void{
    
  }
  /**
   * 
   * After view init
   * 
   */
  ngAfterViewInit(): void {
    this.setAnimation();
  }


}
