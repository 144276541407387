import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

declare var $:any;

@Component({
  selector: 'partial-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {
 
  @ViewChild("header") header:any;
  @Input("classCustom") classCustom:any = "";
  public configuracao = null;
  public lojaAtivo    = false;

  constructor(
    private dataService: DataService,
    private router: Router
  ){}
  /**
   * 
   * To route
   * 
   */
  toRoute(link:any){

    this.router.navigateByUrl(link);
    return false;

  }
  /**
   * 
   * On resize
   * 
   */
  onResize(){

    if(window.innerWidth >= 767){
      this.classCustom = "";
    }else{
      this.classCustom = "mobile";
    }

  }
  /**
   * 
   * Seta a Class Fixed para o topo
   * 
   */
   setTopFixed(){

    let header = this.header.nativeElement;

    $(window).scroll(() => {
      
      var fromTop     = $(window).scrollTop();
      var windowWidth = $(window).width();
      
      if(fromTop > 200){
        header.classList.add("animated","fadeIn","fixed");
      }else{
        header.classList.remove("fixed","animated","fadeIn");
      }

    });

  }
  /**
   * 
   * Set configuracao
   * 
   */
  setConfiguracao(){

    this.configuracao = this.dataService.getConfiguracao();

    if(this.configuracao != null){

      this.lojaAtivo = this.configuracao.loja_ativo;

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setConfiguracao();
  }
  /**
   * 
   * After load
   * 
   */
  ngAfterViewInit(){

    this.setTopFixed();
    this.onResize();
    
  }

}
