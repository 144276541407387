import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'modulo-banner-slider-two',
  templateUrl: './banner-slider-two.component.html',
  styleUrls: ['./banner-slider-two.component.scss']
})
export class BannerSliderTwoComponent implements OnInit {

  @Input("data")  data               = null; 
  @Input("template")  template       = null; 
  @Input("classCustom")  classCustom = null; 
  @ViewChild("banner") banner:ElementRef;
  @ViewChild("sliderNav") sliderNav:ElementRef;
  public banners       = []; 
  public width         = null;
  public open          = true

  constructor(
    private route: Router
  ){

  }
  /***
   * 
   * To route
   * 
   */
  toRoute(item){

    let url = null;

    if(item.link_tipo == 2){

      url = "/produtos/"+item.categoria+"/"+item.produto_apelido;

    }else if(item.link_tipo == 3){

      url = "/produtos/"+item.categoria;

    }else if(item.link_tipo == 4){

      url = "/produtos/colecao/"+item.colecao;

    }

    this.route.navigateByUrl(url); 
    return false;   

  }
  /**
   * 
   * Inicializa o banner
   * 
   */
  initBanner(){

    $(this.banner.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 1,
      dots: false, 
      arrows: false,
      centerMode: false,
      autoplay: true,
      autoplaySpeed: 2500,
      centerPadding: '0',
      //asNavFor: this.sliderNav.nativeElement,
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 1,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 1,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 

    $(this.banner.nativeElement).slick('refresh'); 

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkProduto(banner){

    return "/servicos/"+banner.categoria_apelido+"/"+banner.produto_apelido;

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkCategoria(banner){

    return "/servicos/"+banner.categoria;

  }
  /**
   * 
   * To cidades
   * 
   */
  toCidades(){

    this.route.navigateByUrl("/a-serra-gaucha");   
    
  }
  /**
   * 
   * Prev
   * 
   */
  prev(){

    $(this.banner.nativeElement).slick("slickPrev");

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.banner.nativeElement).slick("slickNext"); 
  
  }
  /**
   * 
   * On resize
   * 
   */
   onResize(){

    this.width = window.innerWidth;

    this.open = false;

    setTimeout(() => {
      this.open = true; 
    },300); 
    setTimeout(() => {
      $(this.banner.nativeElement).slick('refresh');
    },100);
       
  }
  /**
   * 
   * Get image
   * 
   */
  getImage(i){

    let image = null;

    if(this.width <= 700){
      image = i.imagem_mobile;
    }else{
      image = i.imagem; 
    }

    return image;

  }
  /***
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.width = window.innerWidth;
  }
  /**
   * 
   * After View loaded
   * 
   */
  ngAfterViewInit(): void {
    this.initBanner();
  }

}
