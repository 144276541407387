<header class="header {{classCustom}}" #header (window:resize)="onResize()">
    <div class="topo">
        <div class="menu-topo">
            <div class="container">
                <ul>
                    <li>
                        <a href="/contato" title="Contato" (click)="toRoute('/contato')">
                            Entre em contato conosco
                        </a>
                    </li>
                    <li class="divisor">
                        <span>|</span>
                    </li>
                    <li>
                        <modulo posicao="contato" template="topo-telefone"></modulo>
                    </li>
                    <li class="divisor hide-mobile">
                        <span>|</span>
                    </li>
                    <li class="hide-mobile">
                        <modulo posicao="contato" template="topo-email"></modulo>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <modulo class="logo-content" posicao="logo"></modulo>
                </div>
                <div class="col-md-8 center-flex col-menu">
                    <div class="content-menu">
                        <menu-partial [classCustom]="classCustom"></menu-partial>
                    </div>
                </div>
            </div>
        </div>     
    </div>
</header>  

<!--<header class="header {{classCustom}}" #header>
    <div class="topo">
        <modulo class="logo-content" posicao="logo"></modulo>
    </div>    
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-2">
                    <div class="container-logo wow slideInDown" data-wow-delay="0.5s">
                        <modulo class="logo-content" posicao="logo"></modulo>
                    </div>
                </div>
                <div class="col-md-10 col-menu">
                    
                </div>    
            </div> 
            <div class="content-menu">
                <menu-partial [classCustom]="classCustom"></menu-partial>
            </div>
        </div>     
    </div>   
</header>-->  
