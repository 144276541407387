<footer>
  <div class="content">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <h3 class="title-h3">Institucional</h3>
                <ul class="menu-list">
                    <li>
                        <a routerLink="/quem-somos" title="Sobre Nós">
                            Sobre Nós
                        </a> 
                    </li>
                    <li>
                        <a routerLink="/produtos" title="Nossos Produtos">
                            Nossos Produtos
                        </a> 
                    </li>
                    <li>
                        <a href="#" title="Contato">
                            Contato
                        </a> 
                    </li>
                    <li>
                        <a href="#" title="Perguntas Frequentes">
                            Perguntas Frequentes
                        </a> 
                    </li>
                </ul>
            </div>
            <div class="col-md-3 col-border">
                <modulo posicao="contato"></modulo>
                <modulo posicao="rede-social"></modulo>
            </div>
            <div class="col-md-3"> 
                <modulo posicao="endereco"></modulo>
            </div>
            <div class="col-md-3">
                <figure class="logo-footer">  
                    <img src="assets/logos/logo-completo-01.svg" alt="Rent Health" width="200px"/>
                </figure>
            </div>
        </div>
    </div>  
  </div> 
  <copyright></copyright>
</footer>    