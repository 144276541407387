import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{ 
  path: '',     
  //loadChildren: () => HomeModule  
  loadChildren: () => import('src/app/modules/home/home.module').then(m => m.HomeModule) 
},{ 
  path: 'quem-somos',   
  loadChildren: () => import('src/app/modules/sobre/sobre-module/sobre.module').then(m => m.SobreModule) 
},{
  path: 'carrinho',  
  loadChildren: () => import('src/app/modules/checkout/checkout-carrinho/checkout-carrinho.module').then(m => m.CheckoutCarrinhoModule) 
},{
  path: 'dicas',  
  loadChildren: () => import('src/app/modules/pages/dica/dica-page/dica-page.module').then(m => m.DicaPageModule) 
},{
  path: 'carrinho/pedido',     
  loadChildren: () => import('src/app/modules/pages/checkout/checkout-pedido-page/checkout-pedido-page.module').then(m => m.CheckoutPedidoPageModule)  
},{
  path: 'carrinho/pedido/finalizado',     
  loadChildren: () => import('src/app/modules/pages/checkout/checkout-finalizado-page/checkout-finalizado-page.module').then(m => m.CheckoutFinalizadoPageModule)  
},{ 
  path: 'produtos/tipo/:apelido_tipo',    
  loadChildren: () => import('src/app/modules/pages/produto/produtos-page/produtos-page.module').then(m => m.ProdutosPageModule) 
},{ 
  path: 'produtos/colecao/:apelido_colecao',    
  loadChildren: () => import('src/app/modules/pages/produto/produtos-page/produtos-page.module').then(m => m.ProdutosPageModule) 
},{ 
  path: 'produtos/:apelido_categoria/:apelido_produto',   
  loadChildren: () => import('src/app/modules/pages/produto/produto-page/produto-page.module').then(m => m.ProdutoPageModule)
},{ 
  path: 'produtos/:apelido_categoria/:apelido_produto',   
  loadChildren: () => import('src/app/modules/pages/produto/produto-page/produto-page.module').then(m => m.ProdutoPageModule)
},{
  path: 'produtos',
  loadChildren: () => import('src/app/modules/pages/produto/produtos-page/produtos-page.module').then(m => m.ProdutosPageModule) 
},{ 
  path: 'produtos/:apelido_categoria',    
  loadChildren: () => import('src/app/modules/pages/produto/produtos-page/produtos-page.module').then(m => m.ProdutosPageModule) 
},{
  path: 'contato',   
  loadChildren: () => import('src/app/modules/contato/contato.module').then(m => m.ContatoModule) 
},{
  path: 'locacao',     
  loadChildren: () => import('src/app/modules/pages/locacao/locacao-page/locacao-page.module').then(m => m.LocacaoPageModule) 
},{
  path: 'politicas-de-privacidade',
  loadChildren: () => import('src/app/modules/politica/politica-privacidade/politica-privacidade.module').then(m => m.PoliticaPrivacidadeModule)
},{
  path: 'politicas-de-cookies', 
  loadChildren: () => import('src/app/modules/politica/politica-cookie/politica-cookie.module').then(m => m.PoliticaCookieModule)
},{
  path: 'depoimento/sua-avaliacao', 
  loadChildren: () => import('src/app/modules/pages/depoimento/depoimento-page/depoimento-page.module').then(m => m.DepoimentoPageModule)
},{
  path: 'depoimento/sua-avaliacao/enviada', 
  loadChildren: () => import('src/app/modules/pages/depoimento/depoimento-sucesso-page/depoimento-sucesso-page.module').then(m => m.DepoimentoSucessoPageModule)
},{ 
  path: 'minha-conta/perfil',    
  loadChildren: () => import('src/app/modules/pages/minha-conta/meu-perfil-page/meu-perfil-page.module').then(m => m.MeuPerfilPageModule) 
},{ 
  path: 'minha-conta/pedidos',    
  loadChildren: () => import('src/app/modules/pages/minha-conta/meu-pedido-page/meu-pedido-page.module').then(m => m.MeuPedidoPageModule) 
},{   
  path: '**',
  pathMatch: 'full', 
  redirectTo: ''
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
