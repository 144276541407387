import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'modulo-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent {

  @Input("data") data:any;
  @Input("template") template = null;
  @Input("classCustom") classCustom;
  
  constructor(
    public router: Router
  ){}

  /**
   * 
   * Router
   * 
   */
  _route(){  

    this.router.navigateByUrl("quem-somos");
    return false;  

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}  

  
}
