<div *ngFor="let m of modulos" [ngSwitch]="m.tag">
  <div *ngSwitchCase="'logo'">  
    <modulo-logo [data]="m" [classCustom]="classCustom" [template]="template"></modulo-logo>  
  </div>
  <div *ngSwitchCase="'whatsapp'">   
    <modulo-whatsapp [carrinho]="carrinho" [data]="m" [classCustom]="classCustom" [template]="template"></modulo-whatsapp>  
  </div>
  <div *ngSwitchCase="'rede-social'">   
    <modulo-rede-social [data]="m" [classCustom]="classCustom" [template]="template"></modulo-rede-social>  
  </div>
  <div *ngSwitchCase="'endereco'">     
    <modulo-endereco [data]="m" [classCustom]="classCustom" [template]="template"></modulo-endereco>  
  </div>
  <div *ngSwitchCase="'banner-slider'"> 
    <modulo-banner-slider-two [data]="m" [classCustom]="classCustom" [template]="template"></modulo-banner-slider-two> 
  </div>
  <div *ngSwitchCase="'newsletter'"> 
    <modulo-newsletter [data]="m" [classCustom]="classCustom" [template]="template"></modulo-newsletter> 
  </div>
  <div *ngSwitchCase="'contato'">   
    <modulo-contato [data]="m" [classCustom]="classCustom" [template]="template"></modulo-contato> 
  </div> 
  <div *ngSwitchCase="'fale-conosco'">  
    <modulo-fale-conosco [data]="m" [classCustom]="classCustom" [template]="template"></modulo-fale-conosco> 
  </div>   
  <div *ngSwitchCase="'video'">         
    <modulo-video [data]="m" [template]="template" [classCustom]="classCustom"></modulo-video> 
  </div>
  <div *ngSwitchCase="'depoimentos'">            
    <modulo-depoimentos [data]="m" [template]="template" [classCustom]="classCustom"></modulo-depoimentos> 
  </div>
  <div *ngSwitchCase="'produto-destaque-1'">  
    <modulo-produto-destaques [data]="m" [template]="template" [classCustom]="classCustom"></modulo-produto-destaques>
  </div>
  <div *ngSwitchCase="'atendimento-personalizado'">       
    <modulo-atendimento-personalizado [data]="m" [template]="template" [classCustom]="classCustom"></modulo-atendimento-personalizado> 
  </div>
  <div *ngSwitchCase="'pilares'">      
    <modulo-pilares [data]="m" [template]="template" [classCustom]="classCustom"></modulo-pilares> 
  </div>
  <div *ngSwitchCase="'sobre'">  
    <modulo-sobre [data]="m" [template]="template" [classCustom]="classCustom"></modulo-sobre> 
  </div>
  <div *ngSwitchCase="'parceiros'">  
    <modulo-parceiros [data]="m" [template]="template" [classCustom]="classCustom"></modulo-parceiros> 
  </div>
</div>
  
  
  