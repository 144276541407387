<div class="copyright"> 
      <div class="container">
          <div class="content">
            <span>Copyright © 2023 - Todos os direitos reservados</span>
            <a href="https://digitalmovement.com.br/" target="_blank" title="Digital Movement - Soluções em Tecnologia">
                By <strong>Digital Movement</strong>  
                <img src="/assets/logos/logo-dm.svg" alt="Digital Movement" width="20px" height="20px" />
            </a>
          </div>
      </div>    
</div>    