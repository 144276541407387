<div class="menu-partial {{classCustom}} template-{{template}}" (window:resize)="onResize()">
    <button type="button" class="btn-open" (click)="_open()">
        <i class="fa-solid fa-bars"></i>
    </button>  
    <div class="close" #close (click)="_open()">  
        <i class="fa-solid fa-xmark"></i>
    </div>    
    <div class="content" #content> 
        <div class="content-logo">
            <img src="images/logos/logo-rental.svg" alt="Rental Health" width="100px" />
        </div>
        <ul>
            <li>
                <a href="/" title="Home" (click)="toRoute('/')">
                    <span>Home</span>
                </a>    
            </li>
            <li>
                <a href="/quem-somos" title="Quem Somos - Rental Health" (click)="toRoute('quem-somos')">
                    <span>Sobre nós</span>
                </a>    
            </li>
            <li>
                <a href="/produtos" title="Nossos Produtos - Rental Health" (click)="toRoute('/produtos')">
                    <span>Nossos Produtos</span>
                </a>    
            </li>
            <li>
                <a href="/locacao" title="Locação de Equipamentos - Rental Health" (click)="toRoute('/locacao')">
                    <span>Locação de Equipamentos</span>
                </a>    
            </li>
            <li>
                <a href="/contato" title="Contato" (click)="toRoute('/contato')">
                    <span>Contato</span>
                </a>    
            </li>
        </ul> 
    </div>   
</div> 
<div class="overlay" #overlay></div>   
