<div class="product-item item" (click)="_click()">
  <div class="capa" *ngIf="produto.foto != null">
    <figure>    
      <img *ngIf="isLazy" defaultImage="/images/produto/sem-foto/default-capa.jpg" [lazyLoad]='produto.foto' [alt]="produto.foto_alt" width="263px" height="174px" />
      <img *ngIf="!isLazy" [src]='produto.foto' [alt]="produto.foto_alt" width="263px" height="174px" />
    </figure>
  </div>
  <div class="capa" *ngIf="produto.foto == null">
    <a [href]="link" (click)="_click()" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
      <img itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" [alt]="produto.nome" width="263px" height="174px" />
    </a> 
  </div>
  <div class="content">
    <h3>{{produto.nome}}</h3>
    <article *ngIf="produto.descricao_abreviada != null && produto.descricao_abreviada != ''" [innerHtml]="produto.descricao_abreviada | safeHtml"></article>
  </div>
</div>

  