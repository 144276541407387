<div class="module module-products">  
  <div class="module-content">
      <div class="container"> 
          <div class="row">
              <div class="col-md-4">
                  <div class="content-left">
                      <div class="linha wow slideInDown" data-wow-delay="1.5s"></div> 
                      <h2 class="wow animTypeWriter" *ngIf="data.mostrar_titulo">{{data.titulo}}</h2>
                      <h3 class="wow fadeIn" data-wow-delay="1s" *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
                      <div class="content-button wow slideInUp" data-wow-delay="1.2s">
                        <a href="/produtos" routerLink="/produtos" title="Saiba Mais" (click)="toRoute()">
                          Saiba mais
                        </a>
                      </div>
                  </div>
              </div>
              <div class="col-md-8">
                  <div class="products slider-items" #slider>  
                      <div class="slider-item" *ngFor="let p of data.produtos">
                        <produto-item [data]="p"></produto-item>
                      </div>      
                  </div>  
                  <div class="slide-navigate-item prev" (click)="prev()">
                      <img src="images/icons/prev.svg" alt="Prev" width="30px" />
                  </div>
                  <div class="slide-navigate-item next" (click)="next()">
                      <img src="images/icons/next.svg" alt="Next" width="30px" />
                  </div>
              </div>
          </div>
      </div>
  </div>    
</div>    

