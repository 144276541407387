<div class="module module-depoimento">
  <div class="module-title" *ngIf="data.mostrar_titulo">
      <div class="container">
          <h2 class="wow slideInDown" data-wow-delay="0.5s">{{data.titulo}}</h2>
          <h3 *ngIf="data.mostrar_subtitulo" class="wow slideInUp" data-wow-delay="0.5s">{{data.subtitulo}}</h3>
      </div>
  </div>
  <div class="module-content">
      <div class="container">
          <div class="depoimentos slider-items" #slider>
              <div class="slider-item"*ngFor="let d of data.depoimentos">
                <depoimento-item [data]="d"></depoimento-item>
              </div>
          </div>
          <div class="slide-navigate-item prev" (click)="prev()">
              <img src="images/icons/prev.svg" alt="Prev" />
          </div>
          <div class="slide-navigate-item next" (click)="next()">
              <img src="images/icons/next.svg" alt="Next" />
          </div> 
      </div>
  </div>
</div>
    