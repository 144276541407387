import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-parceiros',
  templateUrl: './parceiros.component.html',
  styleUrls: ['./parceiros.component.scss']
})
export class ParceirosComponent implements OnInit,AfterViewInit{
  
  @Input("data") data                  = null; 
  @Input("posicao") position           = null; 
  @Input("template") template          = null;
  @Input("classCustom") classCustom    = ""; 
  
  /**   
   * 
   * Init
   * 
   */
  ngOnInit():void{}
  /**
   * 
   * After
   * 
   */
  ngAfterViewInit():void{}
  
}
