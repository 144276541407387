import { Component, Input } from '@angular/core';

@Component({
  selector: 'parceiro-item',
  templateUrl: './parceiro-item.component.html',
  styleUrls: ['./parceiro-item.component.scss']
})
export class ParceiroItemComponent {

  @Input("data") data:any = {};

  constructor() { }

  /**
   * 
   * Open
   * 
   */
  open(){

    if(this.data.link_ativo){
      window.open(this.data.link,"_blank");
      return false;
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
