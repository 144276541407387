<div class="module module-sobre">
      <div class="container">
          <div class="linha-top"></div>
          <div class="row">
              <div class="col-md-4">
                  <div class="napoleao wow slideInLeft" *ngIf="data.params.imagem != null && data.params.imagem != ''">
                      <figure>
                          <img src="images/sobre/{{data.params.imagem}}" alt="Sobre a Rental Health" width="100%" />
                      </figure>
                  </div>
              </div>
              <div class="col-md-8">
                  <div class="content-left">  
                      <div class="linha-left wow slideInLeft" data-wow-delay="1.5s"></div>
                      <div class="content-text">  
                          <div class="linha wow slideInDown" data-wow-delay="1s"></div>
                          <h2 class="wow animTypeWriter">{{data.titulo}}</h2>
                          <article [innerHtml]="data.params.texto | safeHtml"></article>
                          <div class="content-button wow slideInUp" data-wow-delay="1s">
                              <a href="/quem-somos" routerLink="quem-somos" title="Quem Somos" (click)="_route()">
                                  <span>Conheça mais</span>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
</div>