import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { GtagService } from 'src/app/services/gtag.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'modulo-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  
  @Input("data") data;
  @Input("template") template; 
  @Input("classCustom") classCustom; 
  public dataItem:any = {};
  public loader       = false;   
  public message = { 
    data: "",
    class: "",
    show: false 
  };
  public subscribeMessage = null;
  public messageLoader = "Enviando. Aguarde!"; 

  constructor(
    private app: AppService,
    private api: ApiService,
    private gtag: GtagService,
    private recaptchaV3Service: ReCaptchaV3Service
  ){ }

  /**
   * 
   * Envia os dados do contato para a news
   * 
   */
  send(){

    try{

      this.loader = true; 

      this.recaptchaV3Service.execute('importantAction')
        .subscribe((token) => {

          this.dataItem.token = token;

          this.api.contato().send(this.dataItem).subscribe(response => {

            this.loader = false;

            switch(response.status){
              case 1:
                
                this.app.info("Recebemos o seu contato. Muito Obrigado.");
                this.initDataItem();
                
              break;
              case 0:
                
                let errors = this.app.getErrors([response.data]);
                this.app.info(errors);

              break;
              default:
                this.app.info("Houve um erro interno. Tente mais tarde ou informe nossa equipe.");
              break;
            }

          },(response) => {

            this.loader           = false;
            let error = this.api.formatError(response);
            this.app.info(error.message); 
        

          });
          

      },(response) => {

          this.loader         = false; 
          let error           = this.api.formatError(response);
          let messages        = error.message; 
          this.app.infoError(messages); 

      });


    }catch(e){

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: null,
      apelido: "newsletter",
      tipo: 1,
      token: null
    }

  }
  /**
   * 
   * Converte para string o dataItem
   * 
   */
  toString(){

    return JSON.stringify(this.dataItem);
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {
    this.initDataItem();
  }
  

}
