import { Component, OnInit, Input, AfterViewInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router,Event as NavigationEvent } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'menu-partial',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input("classCustom") classCustom = "";
  @ViewChild("content") content:any; 
  @ViewChild("close") close:any; 
  @ViewChild("overlay") overlay:any;   
  @Input("template") template = "default";
  public show = false;
  public openMenu = false;
  public colecoes     = [];
  public dataColecoes = [];
  public showMenu     = false;
  public showMenu1    = false;
  public categorias   = [];
  public dataCategorias = [];

  constructor(
    private router: Router,
    private dataService: DataService
  ){ }
  
  /**
   * 
   * To route
   * 
   */
  toRoute(link:any){

    this.router.navigateByUrl(link);
    return false;

  }
  /**
   * 
   * Open Menu
   * 
   */
  _openMenu(){

    this.openMenu = this.openMenu ? false : true;

  }
  /**
   * 
   * Open
   * 
   */
  _open(){

    if(this.show){

      this.content.nativeElement.classList.remove("slide-right");
      this.content.nativeElement.classList.add("slide-left");

      setTimeout(() => {
        this.content.nativeElement.classList.remove("show");
      },200);
      this.overlay.nativeElement.classList.remove("show");
      this.close.nativeElement.classList.remove("show");
      this.show = false;

    }else{

      this.content.nativeElement.classList.add("slide-right");

      setTimeout(() => {
        this.content.nativeElement.classList.add("show");
      },200);

      this.content.nativeElement.classList.remove("slide-left"); 
      this.overlay.nativeElement.classList.add("show");
      this.close.nativeElement.classList.add("show");
      this.show = true;

    }

  }
  /**
   * 
   * On resize
   * 
   */
   onResize(){

    if(window.innerWidth >= 959){
      this.content.nativeElement.classList.remove("slide-right");
      this.content.nativeElement.classList.remove("slide-left");
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
