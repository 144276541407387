<div class="modulo module-valores">
      <div class="container">
          <div class="row">
              <div class="col-md-4" *ngFor="let d of data.pilares">
                  <div class="item">
                      <div class="icon wow slideInLeft" data-wow-delay="0.5s">
                          <img [src]="d.icone" [alt]="d.icone"  />
                      </div>
                      <h3 class="wow slideInDown" data-wow-delay="0.8s">{{d.titulo}}</h3>
                  </div>
              </div>
          </div>
      </div>
      <div class="frase">  
          <div class="container">
              <p class="wow text-animation" data-delay="0.5s">
                  Mudando vidas através da medicina há mais <strong>22 anos!</strong>
              </p>
          </div>
      </div> 
  </div>