<div class="module-whatsapp" (click)="open()" *ngIf="template == null">
  <div class="content">
      <div class="header">
          <div class="icon-left">
              <i class="fa-brands fa-whatsapp"></i>
          </div>    
          <span>Fale com <br>um atendente</span> 
      </div>
  </div>   
</div>    
<div class="card-item-contact wow slideInUp" data-wow-delay="0.4s" *ngIf="template == 'page-contato'" (click)="open()">
    <div class="card-item-header tel">
        <h3>Fale por WhatsApp</h3>
        <div class="content">
            <div class="icon">
                <i class="fa-brands fa-whatsapp"></i>
            </div> 
            <div class="text">
              <span>{{data.params.telefone}}</span>
            </div> 
        </div> 
    </div>
</div>  
