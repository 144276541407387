import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {

  @Input("showTop") showTop:any = true;

  constructor(
    private app: AppService
  ){}

  /**
   * 
   * To top
   * 
   */
  toTop(){

    this.app.toTop();

  }
  /**
   * 
   * To up
   * 
   */
  ngOnInit():void{}

}
