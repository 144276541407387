import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoItemComponent } from 'src/app/components/produto/produto-item/produto-item.component';
import { ProdutoItemTwoComponent } from 'src/app/components/produto/produto-item-two/produto-item-two.component';
import { PipeModule } from '../../pipe/pipe.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ProdutoItemValorModule } from '../produto-item-valor/produto-item-valor.module';
import { ProdutoCategoriaItemComponent } from 'src/app/components/produto/produto-categoria-item/produto-categoria-item.component';
import { ProdutoGaleriaComponent } from 'src/app/components/produto/produto-galeria/produto-galeria.component';
import { BtnCartAddModule } from '../../carrinho/btn-cart-add/btn-cart-add.module';
import { ProdutoItemSliderTwoComponent } from 'src/app/components/produto/produto-item-slider-two/produto-item-slider-two.component';
import { ProdutoItemCatalogoComponent } from 'src/app/components/produto/produto-item-catalogo/produto-item-catalogo.component';
import { ProdutoItemMobileComponent } from 'src/app/components/produto/produto-item-mobile/produto-item-mobile.component';
import { PodutoGalleryModalComponent } from 'src/app/components/produto/poduto-gallery-modal/poduto-gallery-modal.component';

@NgModule({
  declarations: [
    ProdutoItemComponent,
    ProdutoItemTwoComponent,
    ProdutoCategoriaItemComponent,
    ProdutoGaleriaComponent,
    PodutoGalleryModalComponent,
    ProdutoItemSliderTwoComponent,
    ProdutoItemCatalogoComponent,
    ProdutoItemMobileComponent
  ],
  exports: [   
    ProdutoItemTwoComponent,
    ProdutoItemComponent,
    ProdutoCategoriaItemComponent,
    ProdutoGaleriaComponent,
    ProdutoItemSliderTwoComponent,
    ProdutoItemCatalogoComponent,
    ProdutoItemMobileComponent,
    PodutoGalleryModalComponent
  ],
  entryComponents: [
    PodutoGalleryModalComponent
  ],
  imports: [
    ProdutoItemValorModule,
    LazyLoadImageModule,
    PipeModule,   
    CommonModule,
    BtnCartAddModule
  ]
})
export class ProdutoPartialModule { }
