<div class="item">   
    <div class="capa" *ngIf="data.foto != null && data.foto != ''">
        <img [src]="data.foto" [alt]="data.titulo" width="100px" />
    </div>
    <div class="capa" *ngIf="data.foto == null || data.foto == ''">
        <img src="/images/depoimento/user.jpg" [alt]="data.titulo" width="100px" />
    </div>
    <h3>{{data.nome}}</h3>
    <div class="instagram" *ngIf="data.instagram_ativo">
        <a [href]="data.instagram_link" target="_blank">
            <strong>{{data.instagram_conta}}</strong>
        </a>
    </div>
    <article [innerHtml]="data.texto"></article>
</div> 