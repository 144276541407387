<section class="modulo-loader {{classCustom}}">
    <div class="loader-wrap">
      <div class="content">
        <figure class="animated infinite pulse">    
          <img src="/images/rental/marca-simbolo-01.svg" width="50px" height="50px" alt="Rental Health" />
        </figure>   
        <span class="message">{{message}}</span> 
      </div>  
    </div>
    <div class="overlay"></div> 
</section>
    