<div [ngSwitch]="template">
  <section *ngSwitchCase="null" class="default-footer">
    <h3 class="title-h3" *ngIf="data.mostrar_titulo">{{data.titulo}}</h3>
    <ul class="list-contact">
      <li>
        <div class="icon">
            <i class="fa-brands fa-whatsapp"></i>
        </div>
        <div class="text">
            <span>{{data.params.whatsapp}}</span>
        </div>    
    </li>
    <li>
        <div class="icon">
            <i class="fa-solid fa-phone"></i>
        </div>
        <div class="text">
            <span>{{data.params.celular}}</span>
        </div>    
    </li>
    <li>
        <div class="icon">
            <i class="fa-solid fa-envelope"></i>
        </div>
        <div class="text">
            <span>{{data.params.email}}</span>
        </div>    
    </li>               
    </ul>
  </section>
  <div *ngSwitchCase="'topo-email'"> 
    <div class="item-email item-contact">
      <i class="fa-solid fa-envelope"></i>
      {{data.params.email}} 
    </div>
  </div>
  <div *ngSwitchCase="'topo-telefone'">
    <div class="item-contact item-phone">
      <i class="fa-solid fa-phone"></i>
      {{data.params.celular}}
    </div>
  </div>
  <div *ngSwitchCase="'page-contato-telefone'" class="page-contato">
    <div class="card-item-contact wow slideInUp" data-wow-delay="0.4s" (click)="openTel()">
      <div class="card-item-header tel">
          <h3>Fale por Telefone</h3>
          <div class="content">
              <div class="icon icon-tel">
                  <i class="fa-solid fa-phone"></i>
              </div> 
              <div class="text">
                  <span>{{data.params.celular}}</span>  
              </div> 
          </div> 
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'page-contato-email'" class="page-contato">
    <div class="card-item-contact wow slideInDown" data-wow-delay="0.5s" (click)="openEmail()">
      <div class="card-item-header">
        <div class="icon">
            <i class="fa-solid fa-envelope"></i>
        </div>
        <div class="text">
            <h3>{{data.params.email}}</h3>  
        </div>  
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'checkout-pedido'" class="checkout-pedido">
    <a class="content-item" href="tel:{{data.params.celular}}" title="E-mail: {{data.params.celular}}">
      <span class="title">Se preferir, ligue pra nós:</span> 
      <strong class="value">
        <i class="material-icons">call</i>
        {{data.params.celular}}
      </strong>
    </a>
  </div> 
</div>  

