<div class="modulo module-newsletter">
    <div class="container">    
        <div class="content">
            <div class="row">
                <div class="col-md-6">
                   <div class="content-text">
                        <h3 class="wow slideInLeft" data-wow-delay="0.5s" [innerHtml]="data.titulo"></h3>
                        <h4 class="wow slideInUp" data-wow-delay="0.8s">{{data.subtitulo}}</h4>
                    </div>
                </div>
                <div class="col-md-6">  
                    <form class="form-custom" (submit)="send()">
                        <div class="content-form">
                            <div class="content-input">  
                                <div class="item item-input wow fadeIn" data-wow-delay="0.3s">
                                    <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Informe seu nome" />
                                </div>    
                                <div class="item item-input wow fadeIn" data-wow-delay="0.3s">
                                    <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Informe seu email" />    
                                </div>
                                <div class="item item-button">
                                    <button class="btn-three"> 
                                        <span>Enviar</span>
                                    </button> 
                                </div>
                            </div> 
                        </div>  
                    </form> 
                </div>
            </div>   
        </div>
    </div>
</div> 
<loader [hidden]="!loader"></loader>
